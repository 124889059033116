import { Component, Mixins } from "vue-property-decorator";
import { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import { namespace } from "vuex-class";
import * as master from "@/store/modules/master/types";
import { networkErrorMessage } from "./const";
import { State, Mutation } from "vuex-class";
import { SET_IS_PROCESSING, SET_IS_BTN_CLICK } from "#/store/types";
import OtherPageMixin from "#/mixins/otherPageMixin";

const Master = namespace(master.name);

@Component
export default class AxiosMixin extends Mixins(OtherPageMixin) {
  /** タイムアウト時間 */
  timeout = 2000;

  /** ローディング状態 1以上の時、ローディングをしている */
  protected loading = 0;

  /** 強制的にiBowを使う確認ダイアログを表示しているかどうか */
  isOpenedForseUseConfirm = false;

  /** 強制的にiBowを使うかどうか */
  isForseUseResult = 0; //0:未設定 1:使う 2:使わない

  /** リフレッシュトークン検証時の確認を表示したかどうか */
  isOpenedRefTokenErrorConfirm = false;

  /** リロード回数 */
  reloadCount = Number(window.sessionStorage.getItem("reload-count"));

  /** 処理中かどうか */
  @State private readonly is_processing!: boolean;

  @Mutation(SET_IS_PROCESSING) setIsProcessing!: Function;

  @State private readonly is_btn_click!: boolean;

  @Mutation(SET_IS_BTN_CLICK) setIsBtnClick!: Function;

  @Master.Mutation(master.SET_MASTER_BY_TOKEN) setMasterByToken!: Function;

  /** API呼び出しを行う（クライアントエラー時にアラートを表示する） */
  public postJsonCheck(
    url: string,
    param: unknown,
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError, msg: string) => void = () => {
      return;
    }
  ): void {
    this.postJson(
      url,
      param,
      {},
      this.createAlertCodeErrorFunc(sucessFunc),
      catchFunc,
      false, //ローディングダイアログを表示する
      true //エラー時にアラートを表示する
    );
  }

  /** PDF生成を行う */
  public makePdf(
    url: string,
    param: object,
    sucessFunc: (res: AxiosResponse) => void = () => {
      return;
    },
    catchFunc: (error: AxiosError, msg: string) => void = () => {
      return;
    },
    isPreview = true
  ): void {
    if (!isPreview) {
      this.postJsonCheck(url, param, sucessFunc, catchFunc);
      return;
    }

    const otherWindow = window.open("/pdf-open", "_blank");
    this.postJson(
      url,
      param,
      {},
      async res => {
        let url = res.data.PdfPath;
        if (!url) {
          url = res.data.pdf_path;
        }
        this.setWindowUrl(otherWindow, url);

        let message = "PDFが開かない場合は以下のURLをクリックしてください。";
        if (res.data.IsZip == 1) {
          message =
            "100枚を超えるため、PDFをZipとしてダウンロードします。<br />ダウンロードされない場合は以下のURLをクリックしてください。";
          this.setWindowCloseButton(otherWindow);
        }
        if (res.data.code > 0) {
          message = res.data.message;
        }
        this.setWindowMessage(otherWindow, message);

        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
        } else {
          sucessFunc(res);
        }
      },
      (error, msg) => {
        this.setWindowMessage(otherWindow, msg);
        catchFunc(error, msg);
      },
      false, //ローディングダイアログを表示する
      true //エラー時にアラートを表示する
    );
  }

  /** ファイルプレビューを行う */
  protected async previewFile(path: string) {
    const otherWindow = window.open("/file-open", "_blank");
    const url = window.base_url + "/api/preview?path=" + path;
    this.postJson(
      url,
      {},
      {},
      async res => {
        this.setWindowUrl(otherWindow, res.data.url);

        let message =
          "ファイルが開かない場合は以下のURLをクリックしてください。";
        if (res.data.code > 0) {
          message = res.data.message;
        }
        this.setWindowMessage(otherWindow, message);

        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
        }
      },
      (error, msg) => {
        this.setWindowMessage(otherWindow, msg);
      },
      false, //ローディングダイアログを表示する
      true //エラー時にアラートを表示する
    );
  }

  /** レスポンスがBinaryデータ（ファイル）の場合のAPI呼び出しを行う */
  public postJsonBlobResCheck(
    url: string,
    param: object,
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError, msg: string) => void = () => {
      return;
    }
  ): void {
    this.postJson(
      url,
      param,
      { responseType: "blob" },
      this.createAlertCodeErrorFunc(sucessFunc),
      catchFunc,
      false, //ローディングダイアログを表示する
      true //エラー時にアラートを表示する
    );
  }

  /** API呼び出しをローディングを表示せずに実行する */
  public postJsonBackground(
    url: string,
    param: object,
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError, msg: string) => void = () => {
      return;
    },
    isShowAlert = false,
    isIgnore = false
  ): void {
    this.postJson(
      url,
      param,
      {},
      this.createAlertCodeErrorFunc(sucessFunc),
      catchFunc,
      true, //ローディングダイアログを表示しない
      isShowAlert, //デフォルトではエラー時にアラートを表示しない
      isIgnore
    );
  }

  /** API呼び出しをローディングを表示せず、ボタンも押せるようにした状態で実行する */
  public postJsonBackgroundIgnore(
    url: string,
    param: object,
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError, msg: string) => void = () => {
      return;
    }
  ): void {
    this.postJsonBackground(url, param, sucessFunc, catchFunc, false, true);
  }

  /** API呼び出しを実行する。失敗時にアラートを表示しない */
  public postJsonNotAlert(
    url: string,
    param: object,
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError, msg: string) => void = () => {
      return;
    }
  ): void {
    this.postJson(
      url,
      param,
      {},
      this.createAlertCodeErrorFunc(sucessFunc),
      catchFunc,
      false, //ローディングダイアログを表示する
      false //エラー時にアラートを表示しない
    );
  }

  /** API呼び出しを行う */
  private postJson(
    url: string,
    param: unknown,
    option: AxiosRequestConfig,
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError, msg: string) => void,
    isNotLoading: boolean,
    isShowAlert: boolean,
    isIgnore = false
  ) {
    option = this.getAuthorizationOption(option);
    option.headers = {
      ...option.headers,
      ReloadCount: String(this.reloadCount)
    };
    this.postRaw(
      url,
      param,
      option,
      sucessFunc,
      catchFunc,
      isNotLoading,
      isShowAlert,
      isIgnore
    );
  }

  /** API呼び出しを行う */
  private postRaw(
    url: string,
    param: unknown,
    option: AxiosRequestConfig,
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError, msg: string) => void,
    isNotLoading: boolean,
    isShowAlert: boolean,
    isIgnore = false
  ) {
    this.startLoading(isNotLoading, isIgnore);
    window.axios
      .post(url, param, option)
      .then((response: AxiosResponse) => {
        this.endLoading(isNotLoading);

        // Blue/Green対応
        if (response.data && response.data.code === 999) {
          this.reloadCount++;
          window.sessionStorage.setItem(
            "reload-count",
            String(this.reloadCount)
          );
          location.reload();
          return;
        }

        sucessFunc(response);
      })
      .catch((error: AxiosError) => {
        if (error.response && error.response.status == 401) {
          if (option.responseType === "blob") {
            //Binaryの場合、認証エラーは一律トークン検証失敗とみなす。ユーザー重複だった場合、無限ループするため、アラート表示せずに強制的に使う。
            if (option.headers) {
              option.headers.ForceUse = "1";
            }
            this.refToken(
              url,
              param,
              option,
              sucessFunc,
              catchFunc,
              isNotLoading,
              isShowAlert
            );
            return;
          }
          switch (error.response.data.auth_code) {
            case 1:
              //利用者重複
              this.endLoading(isNotLoading);
              this.multiUseErrorExec(
                url,
                param,
                option,
                sucessFunc,
                catchFunc,
                isNotLoading,
                isShowAlert
              );
              break;
            case 99:
              //トークン検証失敗
              this.refToken(
                url,
                param,
                option,
                sucessFunc,
                catchFunc,
                isNotLoading,
                isShowAlert
              );
              break;
            default:
              //サーバーエラー
              this.endLoading(isNotLoading);
              this.showErrorMsg(error, isShowAlert);
              catchFunc(error, this.makeErrorMsg(error));
              break;
          }
        } else {
          //サーバーエラー
          this.endLoading(isNotLoading);
          this.showErrorMsg(error, isShowAlert);
          catchFunc(error, this.makeErrorMsg(error));
        }
      });
  }

  /** サーバーエラーでないエラーをアラートで表示する関数を取得 */
  private createAlertCodeErrorFunc(sucessFunc: (res: AxiosResponse) => void) {
    return async (response: AxiosResponse) => {
      if (response.data.code > 0 && response.data.code < 999) {
        await this.$openAlert(response.data.message);
      } else {
        sucessFunc(response);
      }
    };
  }

  /** 同時端末使用制御 */
  private async multiUseErrorExec(
    url: string,
    param: unknown,
    option: AxiosRequestConfig,
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError, msg: string) => void,
    isNotLoading: boolean,
    isShowAlert: boolean
  ) {
    //確認中であれば、待つ
    while (this.isOpenedRefTokenErrorConfirm) {
      await this.sleep(1000);
    }
    this.isOpenedRefTokenErrorConfirm = true;

    if (this.isForseUseResult === 0) {
      if (
        await this.$openConfirm(
          "現在このIDでiBowを使用している\n状態のiPadやPCがあります。\nこのままiBowを使用すると、\n同一IDにて使用している状態の\nPCやiPad等で書いている\n記録や写真データなどが保存されない\n場合があります。\n\n使用してもよろしいでしょうか？"
        )
      ) {
        this.isForseUseResult = 1;
      } else {
        this.isForseUseResult = 2;
      }
    }

    switch (this.isForseUseResult) {
      case 1:
        //強制的に使う
        if (option.headers) {
          option.headers.ForceUse = "1";
        }
        this.postRaw(
          url,
          param,
          option,
          sucessFunc,
          catchFunc,
          isNotLoading,
          isShowAlert
        );
        break;
      case 2:
        //マイページに遷移する
        location.href = window.auth_frontend_url;
        break;
    }
  }

  /** エラーメッセージを表示する */
  private async showErrorMsg(
    error: AxiosError,
    isShowAlert: boolean
  ): Promise<void> {
    if (this.isNetworkError(error)) {
      await this.$openAlert(networkErrorMessage);
      return;
    }

    if (this.isTooManyRequest(error)) {
      await this.$openAlert(
        "サーバーが大変混み合っています。申し訳ありませんが、しばらくたってからやり直してください。"
      );
      return;
    }

    let msg =
      "画面を正しく更新できませんでした。再度ログインしてお試しください。";

    //業務エラーの場合
    if (error.response && error.response.data.code == 101) {
      msg = error.response.data.message;
    }

    //バリデーションエラーの場合
    if (error.response && error.response.data.code == 100) {
      msg =
        "使用できない文字が入力されている可能性があります。入力内容をご確認ください。";
    }

    if (isShowAlert) {
      await this.$openAlert(msg);
    }
  }

  /** エラーメッセージを生成する */
  private makeErrorMsg(error: AxiosError): string {
    if (this.isNetworkError(error)) {
      return "インターネットに接続されていない可能性があります。インターネットに接続して再度お試しください。";
    }

    if (this.isTooManyRequest(error)) {
      return "サーバーが大変混み合っています。申し訳ありませんが、しばらくたってからやり直してください。";
    }

    let msg =
      "画面を正しく更新できませんでした。再度ログインしてお試しください。";

    //業務エラーの場合
    if (error.response && error.response.data.code == 101) {
      msg = error.response.data.message;
    }

    //バリデーションエラーの場合
    if (error.response && error.response.data.code == 100) {
      msg =
        "使用できない文字が入力されている可能性があります。入力内容をご確認ください。";
    }

    return msg;
  }

  /** ネットワークエラーかどうか */
  private isNetworkError(error: AxiosError) {
    return !!error.isAxiosError && !error.response;
  }

  /** Too Many Requestかどうか */
  private isTooManyRequest(error: AxiosError) {
    return error.response && error.response.status == 429;
  }

  /** トークンの再認証の後、APIを実行する */
  private refToken(
    url: string,
    param: unknown,
    option: AxiosRequestConfig,
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError, msg: string) => void,
    isNotLoading: boolean,
    isShowAlert: boolean
  ) {
    window.axios
      .post(window.auth_backend_url + "/auth/refresh", {
        ref_token: window.reftoken
      })
      .then((response: AxiosResponse) => {
        this.endLoading(isNotLoading);
        this.setToken(response);
        option = this.getAuthorizationOption(option);
        this.postRaw(
          url,
          param,
          option,
          sucessFunc,
          catchFunc,
          isNotLoading,
          isShowAlert
        );
      })
      .catch((error: AxiosError) => {
        this.endLoading(isNotLoading);
        this.tokenError(error, isShowAlert);
      });
  }

  /** トークンの再認証を単体で実行する */
  public refTokenOnly(successFunc: (res: AxiosResponse) => void) {
    this.startLoading();
    window.axios
      .post(window.auth_backend_url + "/auth/refresh", {
        ref_token: window.reftoken
      })
      .then((response: AxiosResponse) => {
        this.endLoading();
        this.setToken(response);
        successFunc(response);
      })
      .catch((error: AxiosError) => {
        this.endLoading();
        this.tokenError(error, true);
      });
  }

  /** トークンを保存する */
  private setToken(response: AxiosResponse) {
    window.token = response.data.token;
    Cookies.set("token", response.data.token, {
      domain: window.cookie_domain
    });
    window.reftoken = response.data.ref_token;
    Cookies.set("reftoken", response.data.ref_token, {
      domain: window.cookie_domain
    });
    //トークンリフレッシュのタイミングでストアのログインユーザーの情報も書き換える
    const base64Url = response.data.token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const payload: master.SetMasterByToken = JSON.parse(jsonPayload);
    this.setMasterByToken(payload);
  }

  /** リフレッシュトークンエラー時の処理を行う */
  private async tokenError(error: AxiosError, isShowAlert: boolean) {
    if (error.response && error.response.status == 401) {
      await this.$openAlert("セッションがタイムアウトになりました。");
      window.token = "";
      Cookies.set("token", "", { domain: window.cookie_domain });
      window.reftoken = "";
      Cookies.set("reftoken", "", { domain: window.cookie_domain });
      location.href = "/";
    } else {
      this.showErrorMsg(error, isShowAlert);
    }
  }

  /** ローディングを開始する */
  private startLoading(background = false, isIgnore = false) {
    if (!background) {
      this.loading++;
    }
    if (this.is_btn_click) {
      if (this.setIsProcessing && !isIgnore) {
        this.setIsProcessing(true);
      }
      if (this.setIsBtnClick) {
        this.setIsBtnClick(false);
      }
    }
  }

  /** ローディングを終了する */
  private endLoading(background = false) {
    if (!background) {
      this.loading--;
    }
    if (this.setIsProcessing) {
      this.setIsProcessing(false);
    }
  }

  /** 指定したミリ秒停止する */
  private sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /** 認証情報を設定する */
  private getAuthorizationOption(
    option: AxiosRequestConfig
  ): AxiosRequestConfig {
    if (process.env.VUE_APP_MODE == "debug") {
      option.headers = {
        ...option.headers,
        AuthorizationIbow: "Bearer " + window.token
      };
    } else {
      option.headers = {
        ...option.headers,
        Authorization: "Bearer " + window.token
      };
    }
    return option;
  }
}
